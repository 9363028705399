<template>
  <div class="layout">
    <Header :is-show-hamburger-button="true" />
    <SidebarWrapper />
    <div class="container layout__content">
      <main class="layout__main layout__main--hidden-sidebar">
        <Nuxt />
      </main>
    </div>
    <Footer />
    <LocationModal v-show="visibleLocationModal" />
    <ModalCompanyAdd :is-show-modal="modalAddCompany" />
    <CookiesAccept />
    <AuthModal v-show="isOpenedAuthModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from './components/Header';
import Footer from './components/Footer';
import SidebarWrapper from './components/Sidebar/Wrapper';
import LocationModal from '@/layouts/components/LocationModal';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';
import ModalCompanyAdd from '~/components/elements/modals/Company/Add';
import AuthModal from '~/components/auth/Modal';
import accountChange from '~/mixins/accountChange';

export default {
  name: 'HiddenSidebarLayout',
  components: {CookiesAccept, Header, Footer, SidebarWrapper, LocationModal, ModalCompanyAdd, AuthModal },
  mixins: [login, accountChange],
  computed: {
    ...mapState({ isOpenedSidebar: 'isOpenedSidebar' }),
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState('profile', ['modalAddCompany']),
    ...mapState('auth', ['isOpenedAuthModal']),
  },
};
</script>
