<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_center font_m font_bold">
          Добро пожаловать!
        </div>
        <button class="close-button" type="button" @click="closeModal">
          <svg-icon name="close" width="15" height="15" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <div class="font font_sm font_center sign-up_message">
        Чтобы выгоднее и быстрее совершать покупки, авторизируйтесь или создайте профиль
      </div>
      <form class="sign-up__form" @submit.prevent="goToSendingSms">
        <div class="sign-up__form-control">
          <div class="font_title-m sign-up__form-input-title">
            Номер телефона
          </div>
          <InputTel
            key="login-phone"
            v-model="phoneMask"
            class="sign-up__form-input"
            placeholder="Номер телефона"
            data-test="phone"
            @onValidate="onValidateHandler"
          />
          <div class="font font_sm font_center font_grey">
            Нажимая “Далее” Вы соглашаетесь с <br />
            <a href="/privacy-policy" target="_blank" class="sign-up__terms-link">Политикой конфиденциальности</a>
          </div>
        </div>
        <div class="sign-up__form-action">
          <Button
            v-if="!isStartPhone || !isSocialAuth"
            size="xl"
            full
            type="submit"
            :disabled="isDisabledNextButton || isStartPhone"
            data-test="get-code"
            class="sign-up-page-button font_no-transform"
          >
            Далее
          </Button>
        </div>
      </form>
    </div>

    <AuthModalFooter v-if="isStartPhone && isSocialAuth" :disabled="isDisabledNextButton" />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import {v4} from 'uuid';
import env from '@/.env.json';
// import { validationMessages } from '@/plugins/validation/messages';
import { authModalPages } from '@/plugins/enums/auth';
// import AuthModalFooter from '@/components/auth/Modal/Footer';
import Button from '@/components/elements/Button';
import InputTel from '~/components/elements/InputTel';

export default {
  name: 'AuthModalSignUpPhone',
  components: {
    InputTel,
    // AuthModalFooter,
    Button,
  },
  data() {
    return {
      authModalPages,
      phoneMask: '',
      isDisabledGetCodeBtn: false,

      validatedPhone: {
        isValid: false,
        international: '',
        number: '',
      },
      isSocialAuth: env.SOCIAL_AUTH,
    }
  },
  mounted() {
    this.phoneMask = this.loginPhoneNumber;
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal', 'loginPhoneNumber']),
    // phoneError() {
    //   let text = '';
    //
    //   if (!this.isStartPhone) {
    //     if (!this.validatedPhone.isValid) text = validationMessages.ERROR_PHONE_INPUT;
    //   }
    //
    //   return text;
    // },
    phoneLength() {
      if (!this.isStartPhone) {
        if (this.validatedPhone.isValid)
          return true;
      }
      return false;
    },
    isStartPhone() {
      return !this.phoneMask;
    },
    isDisabledNextButton() {
      return this.isDisabledGetCodeBtn || !this.phoneLength || this.isStartPhone;
    },
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setAcceptedPhone: 'SET_ACCEPTED_PHONE',
      setNewUser: 'SET_NEW_USER',
      setLoginPhoneNumber: 'SET_LOGIN_PHONE_NUMBER',
    }),
    ...mapActions('auth', ['loginByPhoneSendSms']),
    onValidateHandler(phone) {
      this.validatedPhone = phone
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    async onSubmitPhone() {
      try {
        this.isDisabledResetPassword = true;
        const data = {
          phone: this.validatedPhone.number,
        }
        const apimsSession = v4();
        this.$cookie.set('apims_session', apimsSession);
        localStorage.setItem('apims_session', apimsSession);
        await this.loginByPhoneSendSms(data);
        this.setAcceptedPhone(this.validatedPhone.number);
        this.setCurrentAuthModalPage(authModalPages.SIGN_UP_CODE);
      } catch(error) {
        console.log(error)
      } finally {
        this.isDisabledResetPassword = false;
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    async goToSendingSms() {
      try {
        this.isDisabledGetCodeBtn = true;
        const data = {
          phone: this.validatedPhone.number,
        };
        const apimsSession = v4();
        this.$cookie.set('apims_session', apimsSession);
        localStorage.setItem('apims_session', apimsSession);
        const { isNew } = await this.loginByPhoneSendSms(data);
        this.setNewUser(isNew)
        this.setAcceptedPhone(this.validatedPhone.international);
        this.setCurrentAuthModalPage(authModalPages.SIGN_UP_CODE);
      } catch (error) {
        console.log(error)
      } finally {
        this.isDisabledGetCodeBtn = false;
      }
    },
    goToEnterPassword() {
      this.setLoginPhoneNumber(this.validatedPhone.number);
      this.setCurrentAuthModalPage(authModalPages.ENTER_PASSWORD);
    },
  },
};
</script>
