<template>
  <div
    ref="inputTel"
    class="tel-input"
    :class="{ 'tel-input_error': error}"
    :style="`--tel-dropdown-height: ${(onlyCountries.length * 30) + 5}px`"
  >
    <label class="input__label font font_sm font_grey" :class="labelClass" :for="inputId">
      <slot />
    </label>
    <VuePhoneNumberInput
      :id="inputId"
      v-model="inputValue"
      :default-country-code="defaultCountry"
      :only-countries="onlyCountries"
      :no-example="true"
      :size="size"
      :no-validator-state="true"
      :translations="{
        countrySelectorLabel: 'Код',
        countrySelectorError: '',
        phoneNumberLabel: 'Номер телефона',
        example: ''
      }"
      :border-radius="8"
      :disabled="isDisabled"
      @update="onInputUpdate"
      @contextmenu.native="handleContextMenu"
      @copy.native="handleContextMenu"
      @paste.native="handleContextMenu"
    />
    <div v-if="error" class="tel-input__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'InputTel',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    mode: {
      type: String,
      default: 'international',
    },
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    inputOptions: {
      type: Object,
      default: () => {},
    },
    error: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Введите телефон',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    const defaultDropdown = {
      showDialCodeInList: true,
      showDialCodeInSelection: false,
      showSearchBox: true,
      showFlags: true,
      ignoredCountries: [],
    }

    const defaultInput = {
      placeholder: this.placeholder,
      enabledCountryCode: true,
      showDialCode: true,
    }

    return {
      optionsDropdown: { ...defaultDropdown },
      defaultDropdown: { ...defaultDropdown },

      optionsInput: { ...defaultInput },
      defaultInput: { ...defaultInput },
    }
  },
  computed: {
    inputId() {
      return `input-id-${this.id}`;
    },
    ...mapGetters({
      getAddresses: 'checkout/getAddresses',
      getCountriesList: 'geolocation/getCountriesList',
      getSelectedCountry: 'geolocation/getSelectedCountry',
    }),
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue || '')
      },
    },
    onlyCountries() {
      return this.getCountriesList.map(item => item.value)
    },
    defaultCountry() {
      return this.getSelectedCountry.value
    },
  },
  watch: {
    placeholder() {
      this.optionsInput.placeholder = this.placeholder
    },
  },
  methods: {
    onInputUpdate({ isValid, formatInternational, formattedNumber }) {
      this.$emit('onValidate', { isValid, international: formatInternational, number: formattedNumber })
    },
    handleContextMenu(e) {
      e.preventDefault();
    },
  },
}
</script>
