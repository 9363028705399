<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_center font_m font_bold">
          Регистрация
        </div>
        <button class="close-button" type="button" @click="closeModal">
          <svg-icon name="close" width="15" height="15" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <form class="sign-up__form" @submit.prevent="onSubmitRegistration">
        <div class="sign-up__form-control">
          <div class="font_title-m sign-up__form-input-title">
            Номер телефона
          </div>
          <InputTel
            key="login-phone"
            v-model="loginPhoneNumber"
            class="sign-up__form-input"
            :error="phoneError"
            placeholder="Номер телефона"
            data-test="phone"
            is-disabled
            @onValidate="onValidateHandler"
          />
        </div>
        <div class="sign-up__form-control">
          <div class="font_title-m sign-up__form-input-title">
            Имя<RequiredField />
          </div>
          <CustomInput
            v-model="firstName"
            border-radius="8"
            placeholder="Введите имя"
            :show-error="errors.firstName"
            :error="errors.firstName"
            @focus="handleFocusFirstName"
          />
        </div>
        <div class="sign-up__form-control">
          <div class="font_title-m sign-up__form-input-title">
            Фамилия
          </div>
          <CustomInput
            v-model="lastName"
            border-radius="8"
            placeholder="Введите фамилию"
          />
        </div>
        <div class="sign-up__form-control">
          <div class="font_title-m sign-up__form-input-title">
            E-mail<RequiredField />
          </div>
          <CustomInput
            v-model="email"
            type="email"
            border-radius="8"
            placeholder="Введите e-mail"
            :show-error="errors.email"
            :error="errors.email"
            @focus="handleFocusEmail"
          />
        </div>
        <div class="sign-up__form-control">
          <PasswordInput
            key="login-password"
            v-model="password"
            class="sign-up__form-input font_title-m"
            placeholder="Введите пароль"
            :show-error="errors.password"
            :error="errors.password"
            @focus="handleFocusPassword"
          >
            Пароль<RequiredField />
          </PasswordInput>
        </div>
        <div class="sign-up__form-control">
          <PasswordInput
            key="login-password"
            v-model="repeatPassword"
            class="sign-up__form-input font_title-m"
            placeholder="Подтвердите пароль"
            :show-error="errors.repeatPassword"
            :error="errors.repeatPassword"
            @focus="handleFocusRepeatPassword"
          >
            Подтвердите пароль<RequiredField />
          </PasswordInput>
        </div>
        <div class="font font_sm font_left font_grey mb_sm">
          <CustomCheck
            id="termsAgreement"
            v-model="termsAgree"
            data-test="login-agreement-check"
            name="condition"
            @change="handleTerms"
          >
            <p class="sign-up__form-agreement font">
              Продолжая вы соглашаетесь
              <nuxt-link
                class="sign-up__form-link"
                target="_blank"
                to="/"
              >
                с условиями публичной оферты
              </nuxt-link>
            </p>
          </CustomCheck>
        </div>
        <div class="font font_sm font_center font_grey mb_sm">
          Уже есть аккаунт?
          <a href="#" class="sign-up__terms-link" @click="handleLogin">Войти в аккаунт</a>
        </div>
        <div class="sign-up__form-action">
          <Button
            v-if="!isStartPhone || !isSocialAuth"
            size="xl"
            full
            type="submit"
            :disabled="isDisabledNextButton"
            data-test="get-code"
          >
            Зарегистрироваться
          </Button>
        </div>
      </form>
    </div>

    <AuthModalFooter v-if="isStartPhone && isSocialAuth" :disabled="isDisabledNextButton" />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import env from '@/.env.json';
import { validationMessages } from '@/plugins/validation/messages';
import { authModalPages } from '@/plugins/enums/auth';
// import AuthModalFooter from '@/components/auth/Modal/Footer';
import Button from '@/components/elements/Button';
import InputTel from '~/components/elements/InputTel';
import PasswordInput from '@/components/elements/PasswordInput';
import CustomInput from '@/components/elements/CustomInput.vue';
import CustomCheck from '~/components/elements/CustomCheck.vue';
import RequiredField from '~/components/elements/RequiredField.vue';
import {mergeOptions} from '~/plugins/enums/cart';
import {v4} from 'uuid';

export default {
  name: 'AuthModalSignUpRegistration',
  components: {
    RequiredField,
    CustomCheck,
    InputTel,
    // AuthModalFooter,
    Button,
    PasswordInput,
    CustomInput,
  },
  data() {
    return {
      authModalPages,
      phoneMask: '',
      password: '',
      repeatPassword: '',
      isDisabledGetCodeBtn: false,

      validatedPhone: {
        isValid: false,
        international: '',
        number: '',
      },
      isSocialAuth: env.SOCIAL_AUTH,
      firstName: '',
      lastName: '',
      email: '',
      termsAgree: false,
      submitDisabled: false,
      errors: {
        firstName: null,
        email: null,
        password: null,
        repeatPassword: null,
      },
    }
  },
  computed: {
    ...mapState('geolocation', ['isConfirmedCity']),
    ...mapState('auth', ['isOpenedAuthModal', 'loginPhoneNumber']),
    isStartPhone() {
      return !this.phoneMask;
    },
    phoneError() {
      let text = '';

      if (!this.isStartPhone) {
        if (!this.validatedPhone.isValid) text = validationMessages.ERROR_PHONE_INPUT;
      }

      return text;
    },
    isDisabledNextButton() {
      return !this.termsAgree || this.submitDisabled;
    },
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
      setAcceptedPhone: 'SET_ACCEPTED_PHONE',
      setNewUser: 'SET_NEW_USER',
    }),
    ...mapActions('auth', ['loginByPhoneSendSms', 'registerByPassword', 'fetchUser']),
    ...mapActions('cart', [
      'getCartItems',
      'setMergedCart',
    ]),
    ...mapActions('profile', ['loadProfile']),
    onValidateHandler(phone) {
      this.validatedPhone = phone
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    async goToSendingSms() {
      try {
        this.isDisabledGetCodeBtn = true;
        const data = {
          phone: this.validatedPhone.number,
        };
        const apimsSession = v4();
        this.$cookie.set('apims_session', apimsSession);
        localStorage.setItem('apims_session', apimsSession);
        const { isNew } = await this.loginByPhoneSendSms(data);
        this.setNewUser(isNew)
        this.setAcceptedPhone(this.validatedPhone.international);
        this.setCurrentAuthModalPage(authModalPages.ENTER_PASSWORD);
      } catch (error) {
        console.log(error)
      } finally {
        this.isDisabledGetCodeBtn = false;
      }
    },
    async onSubmitRegistration() {
      try {
        this.submitDisabled = true;
        if (!this.firstName) {
          this.errors = {
            firstName: 'Обязательное поле',
          }
        }
        if (!this.email.match(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
          this.errors = {
            ...this.errors,
            email: 'E-mail введен некорректно',
          }
        }
        if (!this.email) {
          this.errors = {
            ...this.errors,
            email: 'Обязательное поле',
          }
        }
        if (!this.password) {
          this.errors = {
            ...this.errors,
            password: 'Обязательное поле',
          }
        }
        if (this.password !== this.repeatPassword) {
          this.errors = {
            ...this.errors,
            repeatPassword: 'Пароли не совпадают',
          }
        }
        if (this.errors.firstName || this.errors.email || this.errors.password || this.errors.repeatPassword) {
          return;
        }
        const data = {
          phone: this.validatedPhone.number,
          password: this.password,
          firstname: this.firstName,
          lastname: this.lastName ? this.lastName : undefined,
          email: this.email,
        }
        await this.registerByPassword(data);
        // this.setNewUser(isNew);
        this.setAcceptedPhone(this.validatedPhone.international);
        try {
          const isDifferentCarts = await this.$api.cart.checkCartsDifference();

          if (isDifferentCarts) {
            // вернуть если нужно дать пользователю выбор
            // this.viewMergeCartsModal = true;
            await this.$api.cart.sendMergeCartsOption(mergeOptions.BOTH_CARTS);
            this.setMergedCart(true);
          }

          this.getCartItems();
          this.loadProfile();
          await this.fetchUser();

          if(this.isConfirmedCity) {
            this.closeModal();
          } else {
            this.setCurrentAuthModalPage(authModalPages.SELECT_LOCATION);
          }
        } catch (error) {
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.submitDisabled = false;
      }
    },
    handleForgotPassword() {
      this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD);
    },
    handleLogin() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    handleTerms(e) {
      this.termsAgree = e;
    },
    handleFocusFirstName() {
      this.errors = {
        ...this.errors,
        firstName: null,
      };
    },
    handleFocusEmail() {
      this.errors = {
        ...this.errors,
        email: null,
      };
    },
    handleFocusPassword() {
      this.errors = {
        ...this.errors,
        password: null,
      };
    },
    handleFocusRepeatPassword() {
      this.errors = {
        ...this.errors,
        repeatPassword: null,
      };
    },
  },
};
</script>
