<template>
  <div
    class="layout-simple"
    :class="{
      'container--checkout-page': $route.name === 'checkout',
      'container--thank-you-page': $route.path.includes('thank-you'),
    }"
  >
    <header class="layout-simple__header">
      <Logo class="layout-simple__logo" custom-logo="/assets/images/logo/logo-no-text.svg" />
    </header>
    <main class="layout-simple__container container">
      <Nuxt />
    </main>
    <Footer />
    <LocationModal v-show="visibleLocationModal" />
    <ModalCompanyAdd :is-show-modal="modalAddCompany" />
    <CookiesAccept />
    <AuthModal v-show="isOpenedAuthModal" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Footer from './components/Footer';
import Logo from '@/components/elements/Logo';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';
import accountChange from '~/mixins/accountChange';
import LocationModal from '~/layouts/components/LocationModal';
import ModalCompanyAdd from '~/components/elements/modals/Company/Add';
import AuthModal from '~/components/auth/Modal';
import amplitude from '~/plugins/amplitude';

export default {
  name: 'SimpleLayout',
  components: {
    CookiesAccept,
    Footer,
    Logo,
    LocationModal,
    ModalCompanyAdd,
    AuthModal,
  },
  mixins: [login, accountChange],
  mounted() {
    amplitude();
  },
  computed: {
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState('profile', ['modalAddCompany']),
    ...mapState('auth', ['isOpenedAuthModal']),
  },
};
</script>

<style>
html,
body,
#__nuxt,
#__layout {
  height: 100%;
}

.layout-simple {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.layout-simple__header {
  display: flex;
  justify-content: center;

  position: relative;
  height: 5rem;

  margin-bottom: 6.4rem;

  background: var(--color-basic);
}

.container--checkout-page {
  padding: 0;
}
.container--thank-you-page .layout-simple__container {
  width: 100%;
}

.layout-simple__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  margin-bottom: 56px;
}

@media all and (min-width: 1024px) {
  .container--checkout-page {
    padding-left: 40px;
    padding-right: 40px;
  }

  .layout-simple__header {
    padding: 34px 0 70px;
    background: transparent;
  }

  .container--thank-you-page {
    .logo__img {
      height: 40px;
    }
  }
}

@media all and (max-width: 1024px) {
  .layout-simple__container {
    margin-bottom: 32px;
  }

  .container--thank-you-page {
    .layout-simple__header {
      background: transparent;
    }
    .logo-wrapper {
      left: 24px;
      transform: none;
    }
  }
}
</style>
