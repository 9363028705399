export const CACHE_DURATION = 5 * 60 * 1000;
export const SESSION_CHECK_INTERVAL = 60 * 1000;
export const MAX_AGE_COOKIE_CITY =  7 * 24 * 60 * 60;
export const MAX_AGE_COOKIE_AGREE = 365 * 24 * 60 * 60

export const MAX_LENGTH_VALUE_NAME = 128;

export const TIME_CODE_SMS = 59;
export const TIME_CODE_SMS_180 = 179;

export const MODAL_FADE_DURATION = 300;

export const REQUEST_CANCEL_MESSAGE = 'Request has been canceled';
