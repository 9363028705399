<template>
  <ul class="header-user header__buttons font">
    <template v-if="hasSession">
      <client-only>
        <li class="header-user__item header-user__account">
          <nuxt-link
            class="header-user__client font_m"
            :to="{ name: 'profile' }"
          >
<!--            <SvgIcon-->
<!--              v-if="isAccountOrganization"-->
<!--              name="company"-->
<!--              width="24"-->
<!--              height="24"-->
<!--            />-->
<!--            <span v-else class="header-user__client-initials">{{ getAvatarPlaceholder }}</span>-->
            <svg-icon
              name="site-header/account-small"
              width="26.67"
              height="24.47"
              class="header-user__favorite-ico"
            />
          </nuxt-link>

          <HeaderMainActionsAccountMenu />
        </li>

        <li class="header-user__item header-user__favorite">
          <nuxt-link
            class="header-user__favorite-button"
            aria-label="Избранное font_m"
            :to="{ name: 'favorites' }"
          >
            <div v-if="getFavoritesCount" class="header-user__favorite-counter">
              {{ getFavoritesCount }}
            </div>
            <svg-icon
              v-if="getFavoritesCount"
              name="site-header/wishlist-small"
              width="26.67"
              height="24.47"
              class="header-user__favorite-ico"
            />
            <svg-icon
              v-else
              name="site-header/wishlist-small"
              width="26.67"
              height="24.47"
              class="header-user__favorite-ico"
            />
          </nuxt-link>
        </li>
      </client-only>
    </template>

    <li v-else class="header-user__item header-user__login">
      <button
        class="header-user__button"
        data-test="log-in"
        aria-label="Войти"
        @click="openAuthModal"
      >
        <svg-icon name="site-header/account-small" width="24" height="24" />
      </button>
    </li>

    <li class="header-user__item header-user__cart header-user__item__mobile">
      <nuxt-link
        class="header-user__button"
        data-test="go-to-favorites"
        aria-label="Избранное"
        :to="{ name: 'favorites' }"
      >
        <span v-if="getFavoritesCount" class="header-user__cart-count">
          {{ getFavoritesCount }}
        </span>
        <svg-icon name="site-header/wishlist-small" width="21" height="21" />
      </nuxt-link>
    </li>
    <li class="header-user__item header-user__cart">
      <nuxt-link
        class="header-user__button"
        aria-label="Открыть корзину"
        :to="{ name: 'cart' }"
      >
        <span class="header-user__cart-count">
          {{ getCartAllItemsCount.all }}
        </span>
        <svg-icon name="site-header/cart-small" width="27" height="24" />
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import * as amplitudeTracker from '@amplitude/analytics-browser';
import HeaderMainActionsAccountMenu from '~/layouts/components/Header/Main/Actions/AccountMenu';

export default {
  name: 'HeaderMainActions',
  components: {
    HeaderMainActionsAccountMenu,
  },
  data() {
    return {
      isPendingLogout: false,
    }
  },
  watch: {
    hasSession(value) {
      console.log('hasSession', value);
    },
  },
  computed: {
    ...mapState('auth', ['hasSession']),
    ...mapState({ isOpenedSearch: 'isOpenedSearch' }),

    ...mapGetters({
      getCartAllItemsCount: 'cart/getCartAllItemsCount',
      getFavoritesCount: 'favorites/getFavoritesCount',
      getAvatarPlaceholder: 'auth/getAvatarPlaceholder',
      getUserCompanies: 'auth/getUserCompanies',
      isAccountOrganization: 'auth/isAccountOrganization',
    }),
  },
  methods: {
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    openAuthModal() {
      this.setIsOpenedAuthModal(true);
      amplitudeTracker.track('loginform_start', { page: 'main' });
    },
  },
}
</script>

