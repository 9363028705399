import env from '@/.env.json';

export function generatePictureSourcePath(id, ext, x = '', y = '') {
  return `${env.FILE_SERVICE_URL}/content/${id}${x ? `/${x}` : ''}${y ? `/${y}` : ''}/${ext || 'png'}`;
}

export function generateYoutubeVideoSourcePath(videoId, autoplay = true, loop = true) {
  const youTube = 'https://www.youtube.com/embed/'

  return `${youTube}${videoId}?autoplay=${Number(autoplay)}}&loop=${Number(loop)}&playlist=${videoId}`;
}

export function generateYoutubePreview(videoId) {
  return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`
}

/**
 *
 * @param {Number} imageID Идентификатор изображения
 * @param {String} width Ширина для формата 1x
 * @param {String} height Высота для формата 2x
 * @param {String} ext расширение изображения
 * @param {String} src прямой путь до изображения (заглушка)
 * @returns {Object}
 */
export function getImageAllFormats(imageID, width = '', height = '', ext = 'png', src = '') {
  if (src) {
    return {
      [ext]: [src, src],
      webp: [src, src],
    };
  }

  const widthX2 = width * 2;
  const heightX2 = height * 2;

  return {
    [ext]: [
      generatePictureSourcePath(imageID, ext, widthX2, heightX2),
      generatePictureSourcePath(imageID, ext, widthX2, heightX2),
    ],
    webp: [
      generatePictureSourcePath(imageID, 'webp', widthX2, heightX2),
      generatePictureSourcePath(imageID, 'webp', widthX2, heightX2),
    ],
  };
}

export function generateFileOriginalPath(id) {
  return `${env.FILE_SERVICE_URL}/content/${id}`;
}

export function generateStoragePath(path) {
  return `${env.FILE_STORAGE_SERVICE_URL}/${path}`;
}

export function downloadFile(path, target = '_self') {
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('class', 'download-input');
  downloadLink.setAttribute('href', path);
  downloadLink.setAttribute('download', 'download');
  downloadLink.setAttribute('target', target);

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function saveToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.className = 'clipboard-input';
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.select();

  let successful = false;

  try {
    successful = document.execCommand('copy');
  } catch (err) {
    successful = false;
  }

  document.body.removeChild(textArea);
  return successful;
}
