import env from '@/.env.json';
import {priceFormat} from '~/plugins/formatting';

export const state = () => ({
  currentCurrency: env?.CURRENCY || 'RUB',
  symbols: {
    RUB: '₽',
    USD: '$',
    EUR: '€',
    KZT: '₸',
    UZS: 'Soʻm',
  },
});

export const getters = {
  getCurrentSymbol: (state) => state.symbols[state.currentCurrency],
  getCurrency: (state, getters) => (price, digits = 1, symbol = true) => {
    let currency = ` ${getters.getCurrentSymbol}`

    if (!symbol) {
      currency = ''
    }
    const generatedPrice = priceFormat(price, digits);
    let newPrice = '';
    for (let i = 0; i < generatedPrice.length; i++) {
      if (generatedPrice.charCodeAt(i) === 160) {
        newPrice += ' ';
      } else {
        newPrice += generatedPrice[i];
      }
    }

    return `${newPrice}${currency}`
  },
}

export const mutations = {
  setCurrency(state, payload) {
    state.currentCurrency = payload
  },
}
