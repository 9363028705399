import { getProductDemensions } from '@/plugins/helpers/product';

export const state = () => ({
  product: {},
  productOptions: null,
});

export const getters = {
  getPrice: (state, getters, rootState, rootGetters) => {
    return rootGetters['currency/getCurrency'](state.product.price?.value)

  },
  getOldPrice: (state, getters, rootState, rootGetters) => {
    return rootGetters['currency/getCurrency'](state.product.oldPrice?.value)
  },
  getCharacteristicValue: () => (value) => {
    return Array.isArray(value) ? value.join(', ') : value;
  },
  getCategories: (state) => {
    return state.product.categoryCodes.map(elem => elem.name).join(', ')
  },
  getCategoryLink: (state) => {
    const categoryCodes = state.product.categoryCodes;

    return (
      categoryCodes &&
      categoryCodes.length > 0 &&
      categoryCodes[categoryCodes.length - 1].code
    );
  },
  getOptionsCharacteristics: (state, getters) => {
    const characteristics = state.productOptions?.characteristics || [];
    return characteristics.map((item) => {
      const options = item.options.map((option) => ({
        ...option,
        isSelected: getters.isSelected(item.code, option.value),
        isDisabled: getters.isDisabled(item.code, option.value),
      }));
      const selectedOption = options.find((option) => option.isSelected);

      return {
        ...item,
        selectedOption,
        options: options.sort((a, b) => a.value > b.value ? 1 : -1),
      };
    });
  },
  getDimensions: (state) => {
    return getProductDemensions(state.product);
  },
  getCharacteristics: (state) => {
    const dimensions = [];
    if (state.product?.vendorCode) {
      dimensions.push({
        title: 'Артикул',
        value: state.product.vendorCode,
      });
    }
    if (state.product?.width) {
      dimensions.push({
        title: 'Ширина',
        value: `${state.product.width} см`,
      });
    }
    if (state.product?.height) {
      dimensions.push({
        title: 'Высота',
        value: `${state.product.height} см`,
      });
    }
    if (state.product?.length) {
      dimensions.push({
        title: 'Глубина',
        value: `${state.product.length} см`,
      });
    }
    if (state.product?.weight) {
      dimensions.push({
        title: 'Вес',
        value: `${state.product.weight} гр`,
      });
    }

    return [
      ...dimensions,
      ...state.product?.characteristics,
    ];
  },
  getCombinations: (state) => {
    return state.productOptions?.combinations || [];
  },
  getSelectedCombination({ product }, getters) {
    const combinations = getters.getCombinations;
    return combinations.find((item) => item.code === product.code);
  },
  getNextCombination: (state) => (code, value) => {
    const combinations = state.productOptions.combinations || [];
    return combinations.find((item) => item.props[code] === value)
  },
  isSelected: (state, getters) => (code, value) => {
    const selectedCombination = getters.getSelectedCombination;
    return selectedCombination?.props[code] === value;
  },
  isDisabled: (state, getters) => (code, value) => {
    const getCombination = getters.getNextCombination;
    return !getCombination(code, value);
  },
};

export const mutations = {
  SET_PRODUCT(state, payload) {
    state.product = payload;
  },
  SET_PRODUCT_OPTIONS(state, payload) {
    state.productOptions = payload;
  },
};

export const actions = {
  async fetchProduct({ commit, dispatch, state }, { code, category }) {
    try {
      const result = await this.$api.product.fetchProduct({ code });

      if (!result.categoryCodes.some(item => item.code === category)) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            status: 404,
            message: 'Cannot find category',
          },
        };
      }

      const isSameGroup = state.product.variantGroup === result.variantGroup;
      commit('SET_PRODUCT', result);

      if (!isSameGroup) {
        if (result.variantGroup) {
          await dispatch('fetchProductOptions', result.variantGroup);
        } else {
          commit('SET_PRODUCT_OPTIONS', null);
        }
      }
    } catch (error) {
      if (error.response.status === 404) {
        throw error;
      }
      console.error(error);
    }
  },
  async fetchProductOptions({ commit }, payload) {
    try {
      const data = await this.$api.product.fetchProductOptions(payload);
      commit('SET_PRODUCT_OPTIONS', data);
    } catch (error) {
      console.error(error);
    }
  },
};
