import Vue from 'vue'
import VFocus from "./VFocus";
import VClickOutside from './VClickOutside';

const directives = [
  VFocus,
  VClickOutside,
]

directives.forEach(item => {
  Vue.directive(item.name, item.directive)
})
