const items = [
  {
    thumbnailUrl: '/assets/stories/logos/DIOR/dior.svg',
    url: '/dior/',
    title: 'Dior',
    slides: [
      {
        video: 'https://storage.yandexcloud.net/cdn-prod.viled.kz' +
          '/cdv/230717_JDR_LOR_FILM_PRODUIT_20s_916_VA_MASTER_428x843.webm',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/EL/EL-black-300x300.png',
    url: '/brands/estee-lauder/',
    title: 'Estee Lauder',
    slides: [
      {
        image: 'https://static.insales-cdn.com/r/-C1ygvGYFKY/rs:fit:600:600:1' +
          '/plain/files/1/7182/28072974/original/CDV__1_.jpg@webp',
      },
      {
        image: 'https://static.insales-cdn.com/r/I91_rXdDF4c/rs:fit:600:600:1' +
          '/plain/files/1/7185/28072977/original/CDV__2_.jpg@webp',
      },
      {
        image: 'https://static.insales-cdn.com/r/kR7aLgFYzsA/rs:fit:600:600:1' +
          '/plain/files/1/7188/28072980/original/CDV__3_.jpg@webp',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/MAC/MAC-black-300x300.png',
    url: '/mac/',
    title: 'MAC',
    slides: [
      {
        image: 'https://static.insales-cdn.com/r/SvCIE0emWUE/rs:fit:600:600:1' +
          '/plain/files/1/7263/28073055/original/3.1.jpg@webp',
      },
      {
        image: 'https://static.insales-cdn.com/r/iCEaNvdOKSI/rs:fit:600:600:1' +
          '/plain/files/1/7270/28073062/original/3.3.jpg@webp',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/JML/JML-black-300x300.png',
    url: '/jo-malone-london/',
    title: 'Jo Malone London',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb231321K5sbg_12',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb15212w1QJz_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb4123CGXNp_123',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/TF/TF-black-300x300.png',
    url: '/tom-ford/',
    title: 'Tom Ford',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb1wan67_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb3hf0N8_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb2VsRqg_1',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/LM/LM-black-300x300.png',
    url: '/brands/la-mer/',
    title: 'La Mer',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb11116AOmM_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb412142k1Lmi_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb412412ndC1K_123',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/KP/KP-black-300x300.png',
    url: '/brands/kilian-paris/',
    title: 'Kilian',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb123213lquYI_123',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb1232131TebJ3_123',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb23YCy3P_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb231hGTfk_123',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb2162BGE_123',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/DA/DA-black-300x300.png',
    url: '/darphin/',
    title: 'Darphin',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb1214ea5gM_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb2141pB57_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb121422u71P_1',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/FM/FM-black-300x300.png',
    url: '/frederic-malle/',
    title: 'Frederic Malle',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb3Uvt1s_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb1xlqS4_2',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb2uelq6_2',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/CL/CL-black-300x300.png',
    url: '/brands/clinique/',
    title: 'Clinique',
    slides: [
      {
        image: '/assets/stories/slides/CL/1.jpg',
      },
      {
        image: '/assets/stories/slides/CL/2.jpg',
      },
      {
        image: '/assets/stories/slides/CL/3.jpg',
      },
      {
        image: '/assets/stories/slides/CL/4.jpg',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/LSDS/sultane_de_saba_logo.svg',
    url: '/brands/la-sultane-de-saba/',
    title: 'La Sultane De Saba',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb9905kfpNE_2',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb9906jPxBK_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb99073o4lk_1',
      },
      {
        video: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/original/IMG_3393.MOV',
      },
    ],
  },
  {
    thumbnailUrl: '/assets/stories/logos/RG/RATED_GREEN_LOGO.svg',
    url: '/brands/rated-green/',
    title: 'Rated Green',
    slides: [
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb990011oai8_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb992wqfR3_1',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb9902dcH10_2',
      },
      {
        image: 'https://storage.yandexcloud.net/cdn-prod.viled.kz/logo/web/compressed/logoWeb9903afZ6W_1',
      },
    ],
  },
];

export const state = () => ({
  items,
  isOpen: false,
  // текущий дочерний слайд
  currentSlide: 0,
  initialIndex: 0,
});

export const getters = {
  getItems: (state) => state.items,
  getIsOpen: (state) => state.isOpen,
  getCurrentSlide: (state) => state.currentSlide,
  getInitialIndex: (state) => state.initialIndex,
}

export const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  DELETE_ITEMS(state) {
    state.items = [];
  },
  SET_IS_OPEN(state, payload) {
    state.isOpen = payload;
  },
  SET_CURRENT_SLIDE(state, payload) {
    state.currentSlide = payload;
  },
  SET_INITIAL_INDEX(state, payload) {
    state.initialIndex = payload;
  },
}

export const actions = {};
