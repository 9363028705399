<template>
  <div class="sign-up">
    <div class="container--modal-control sign-up__top-control">
      <div class="sign-up__wrapper">
        <div class="sign-up__top-menu font font_m font_bold font_uppercase font_center">
          Введите код из СМС
        </div>
        <button class="close-button" type="button" @click="toStart">
          <svg-icon name="arrow-left" width="16" height="14" />
        </button>
      </div>
    </div>
    <div class="container--modal sign-up__body">
      <h2 class="sign-up__title">
      </h2>
      <p class="sign-up__text sign-up__enter-code font font_m font_center">
        Введите код, отправленный на<br /> {{ acceptedPhone }}
      </p>
      <form class="sign-up__form" autocomplete="off" @submit.prevent="onSubmitCode">
        <div class="sign-up__form-control">
          <Input4Digit
            key="sms-code"
            v-model="code"
            v-focus
            :show-error="failCode"
            data-test="fill-code"
          />
          <div v-if="failCode" class="sign-up__incorrect-code">
            Неверный код, попробуйте еще раз
          </div>
        </div>
        <Alert
          v-show="showErrorAlert"
          class="merge-carts-alert"
          type="error"
          @close="closeErrorAlert()"
        >
          <template #content>
            <p class="font font_m">
              Произошла ошибка при выполнении операции
            </p>
          </template>
        </Alert>

        <div class="sign-up__recall-time font font_m font_grey">
          <button
            class="sign-up__recall-button font font_m font_grey"
            type="button"
            :disabled="isDisabledSubmitButton"
            @click.stop="goToEnterForgotPasswordPhone"
          >
            Изменить номер?
          </button>
        </div>

        <div class="sign-up__form-action">
          <Button
            full
            size="sm"
            :disabled="timerVisible"
            data-test="confirmation"
            @click.stop="goToSendingSms"
          >
            <Timer v-if="timerVisible" ref="timerCode" :seconds="timeCodeSMS" @timerEnd="timerEnd()" />
            <span v-else>Отправить код еще раз</span>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapMutations, mapState } from 'vuex';

import {v4} from 'uuid';
import { TIME_CODE_SMS } from '@/plugins/config';
import { validationMessages } from '@/plugins/validation/messages';
import { authModalPages } from '@/plugins/enums/auth';
import { codeMaskOptions } from '@/plugins/settings';
// import { mergeOptions } from '@/plugins/enums/cart';

import Alert from '@/components/elements/Alert';
import Timer from '@/components/elements/Timer';
import Button from '@/components/elements/Button';
import Input4Digit from '~/components/elements/Input4Digit.vue';

export default {
  name: 'AuthModalForgotPasswordCode',
  components: {
    Input4Digit,
    Alert,
    Timer,
    Button,
  },
  data() {
    return {
      code: null,
      failCode: false,
      codeMaskOptions: { ...codeMaskOptions },
      isDisabledSubmitButton: false,
      timeCodeSMS: TIME_CODE_SMS,
      showErrorAlert: false,
      timerVisible: true,
      termsAgree: null,
    };
  },
  computed: {
    ...mapState('geolocation', ['isConfirmedCity']),
    ...mapState('auth', ['acceptedPhone', 'isNewUser', 'loginPhoneNumber']),
    phone() {
      return this.acceptedPhone.replace(/[^\d+]/g, '')
    },
    codeError() {
      const code = this.$v.code;
      let text = '';
      if (code.$dirty) {
        if (!code.required) text = validationMessages.ERROR_REQUIRED;
        if (!code.minLength) text = validationMessages.ERROR_CODE_INPUT;
      }
      if (!this.termsAgree && this.isNewUser) text = validationMessages.ERROR_AGREEMENT;

      const fail = this.$v.failCode;
      if (fail.$dirty && !fail.valid) text = validationMessages.ERROR_CODE;
      return text;
    },
    isDisabledNextButton() {
      return this.isDisabledSubmitButton || this.$v.code.$dirty || (!this.termsAgree && this.isNewUser);
    },
  },
  watch: {
    code(value) {
      if (this.$v.code.$dirty) this.$v.code.$reset();
      if (this.$v.failCode.$dirty) this.$v.failCode.$reset();
      if (value.length === 4) {
        this.onSubmitCode();
      } else {
        this.failCode = false;
      }
    },
  },
  validations: {
    code: {
      required,
      minLength: minLength(4),
    },
    failCode: {
      valid: (value) => value !== true,
    },
  },
  mounted() {
    this.timerStart();
  },
  beforeDestroy() {
    this.timerStop();
  },
  methods: {
    ...mapMutations('auth', {
      setCurrentAuthModalPage: 'SET_CURRENT_AUTH_MODAL_PAGE',
    }),
    ...mapActions('auth', [
      'resetPasswordCheckCode',
      'loginByPhoneSendSms',
      'fetchUser',
      'resetPasswordSendSms',
    ]),
    ...mapActions('cart', [
      'getCartItems',
      'setMergedCart',
    ]),
    ...mapActions('profile', ['loadProfile']),
    async onSubmitCode() {
      // Подтверждение кода
      this.$v.code.$touch();
      if (!this.$v.code.$invalid) {
        try {
          this.isDisabledSubmitButton = true;
          const data = {
            code: this.code,
            phone: this.phone,
          };
          const apimsSession = localStorage.getItem('apims_session');
          this.$cookie.set('apims_session', apimsSession);
          await this.resetPasswordCheckCode(data);
          this.failCode = false;
        } catch (error) {
          this.failCode = true;
          this.isDisabledSubmitButton = false;
        }

        this.$v.failCode.$touch();

        if (this.failCode) return;
        this.setCurrentAuthModalPage(authModalPages.SET_PASSWORD);

        // try {
        //   const isDifferentCarts = await this.$api.cart.checkCartsDifference();
        //
        //   if (isDifferentCarts) {
        //     // вернуть если нужно дать пользователю выбор
        //     // this.viewMergeCartsModal = true;
        //     await this.$api.cart.sendMergeCartsOption(mergeOptions.BOTH_CARTS);
        //     this.setMergedCart(true);
        //   }
        //
        //   this.getCartItems();
        //   this.loadProfile();
        //   await this.fetchUser();
        //
        //   if(this.isConfirmedCity) {
        //     this.closeModal();
        //   } else {
        //     this.setCurrentAuthModalPage(authModalPages.SET_PASSWORD);
        //   }
        // } catch (error) {
        //   this.showErrorAlert = true;
        // }

        this.isDisabledSubmitButton = false;
      }
    },
    async handleResendCode() {
      const data = {
        phone: this.loginPhoneNumber,
      }
      await this.resetPasswordSendSms(data);
      this.timerStart();
    },
    async goToSendingSms() {
      try {
        this.isDisabledSubmitButton = true;
        const apimsSession = v4();
        this.$cookie.set('apims_session', apimsSession);
        localStorage.setItem('apims_session', apimsSession);
        await this.loginByPhoneSendSms({
          phone: this.phone,
        });
        this.failCode = false;
        this.timerStart();
      } catch (error) {
        console.log(error);
        this.failCode = true;
        this.$v.failCode.$touch();
      } finally {
        this.isDisabledSubmitButton = false;
      };
    },
    goToPhoneNumber() {
      this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD);
    },
    toStart() {
      this.setCurrentAuthModalPage(authModalPages.INDEX);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    timerStart() {
      this.timerVisible = true;
      this.$nextTick(() => {
        this.$refs.timerCode.start();
      });
    },
    timerStop() {
      if (this.$refs.timerCode) {
        this.$refs.timerCode.stop();
      }
      this.timerVisible = false;
    },
    timerEnd() {
      this.timerVisible = false;
    },
    closeErrorAlert() {
      this.showErrorAlert = false;
    },
    goToEnterForgotPasswordPhone() {
      this.setCurrentAuthModalPage(authModalPages.FORGOT_PASSWORD);
    },
  },
};
</script>
