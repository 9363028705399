<template>
  <header v-scroll-lock="isOpenedMobileMenu" class="header">
    <!--    <HeaderTop />-->
    <HeaderLogo />
    <div class="header__hover-container" @mouseleave="handleHeaderMainMouseLeave">
      <HeaderMain
        :opened-mobile-menu="isOpenedMobileMenu"
        :is-scroll-offset="isScrollOffset"
        @changeMobileMenu="onChangeMobileMenu"
      />
      <div class="header-bottom" :class="{ 'header-bottom--scroll' : isScrollOffset }">
        <div class="container header-bottom__container">
          <nav class="menu font">
            <transition name="fade">
              <!--            <HeaderBottomCatalog-->
              <!--              v-show="isShowCategories"-->
              <!--              @linkClick="onLinkClick"-->
              <!--            />-->
              <HeaderBottomCatalogSider
                v-show="getIsShowCategories"
                @linkClick="onLinkClick"
                @backdropMouseenter="handleHeaderMainMouseLeave"
              />
            </transition>
          </nav>
        </div>
      </div>
    </div>
    <transition name="fade">
      <HeaderMobileMenu
        v-show="isOpenedMobileMenu"
        @linkClick="onLinkClick"
      />
    </transition>
  </header>
</template>

<script>
// import HeaderTop from '~/layouts/components/Header/Top';
import {mapGetters, mapMutations} from 'vuex';
import HeaderMain from '~/layouts/components/Header/Main';
// import HeaderBottom from '~/layouts/components/Header/Bottom';
import HeaderMobileMenu from '~/layouts/components/Header/MobileMenu';
import HeaderLogo from '~/layouts/components/Header/Logo/index.vue';
// import HeaderBottomMenu from '~/layouts/components/Header/Bottom/Menu.vue';
// import HeaderBottomCatalog from '~/layouts/components/Header/Bottom/Catalog.vue';
import HeaderBottomCatalogSider from '~/layouts/components/Header/Bottom/CatalogSider.vue';
// import MenuToggle from '~/layouts/components/Header/MenuToggle.vue';

export default {
  name: 'MainHeader',
  components: {
    HeaderBottomCatalogSider,
    // MenuToggle,
    // HeaderBottomCatalog,
    // HeaderBottomMenu,
    HeaderLogo,
    // HeaderTop,
    HeaderMain,
    // HeaderBottom,
    HeaderMobileMenu,
  },
  props: {
    isShowHamburgerButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isScrollOffset: false,
      isOpenedMobileMenu: false,
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'mobileXs';
    },
    routeName() {
      return this.$nuxt.$route.name;
    },
    ...mapGetters('catalog', ['getIsShowCategories']),
    ...mapGetters('favorites', ['getFavoritesCount']),
  },
  watch: {
    mqIsMobile(value) {
      if (value) {
        if (this.getIsShowCategories) {
          this.setIsShowCategories(false)
          this.isOpenedMobileMenu = true
        }
        return
      }

      if (this.isOpenedMobileMenu) {
        this.isOpenedMobileMenu = false;
        this.setIsShowCategories(true)
      }
    },
    $route() {
      this.scrollToTop();
      this.onLinkClick();
    },
    isOpenedMobileMenu(value) {
      value && (this.setIsShowCategories(false))
    },
  },
  mounted() {
    this.scrollToTop();
    const self = this;
    window.addEventListener('scroll', function() {
      self.isScrollOffset = window.scrollY > 110;
    });
  },
  methods: {
    scrollToTop()
    {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    onLinkClick() {
      this.setIsShowCategories(false);
      this.isOpenedMobileMenu = false;
    },
    onChangeMobileMenu(value) {
      this.isOpenedMobileMenu = !this.isOpenedMobileMenu;
    },
    ...mapMutations('catalog', {
      setIsShowCategories: 'SET_IS_SHOW_CATEGORIES',
    }),
    handleHeaderMainMouseLeave() {
      this.setIsShowCategories(false);
    },
  },
};
</script>

<style scoped>
  header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }
</style>

